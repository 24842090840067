<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div v-if="!hasToken" class="container bg-light">
          <div class="alert alert-warning" role="alert">
            <p>Sie haben die tägliche Obergrenze für Fondsdaten-Ansichten erreicht. Bitte melden Sie sich <router-link to="/login">hier</router-link> an um weitere Fondsdaten zu erhalten.</p>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-warning" role="alert">
            <p>Sie haben die monatliche Obergrenze für Fondsdaten-Ansichten erreicht. Bitte Kontaktieren Sie uns um eine Erhöhung Ihres Limits zu vereinbaren.</p>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";

export default {
  name: "ViewLimitReached",
  components: {Hero,HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style scoped>

</style>